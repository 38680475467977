'use client'

import { ReactNode } from "react"
import { ChatContext, useChatHook } from "@/components"


const ChatProvider = ({ children }: { children: ReactNode }) => {
    const provider = useChatHook()
    return (
        <ChatContext.Provider value={provider}>
            {children}
        </ChatContext.Provider>
    )
}

export default ChatProvider