'use client'
import React, { ReactNode, useContext, useState } from 'react'
import { Button, Popover, TextField } from '@radix-ui/themes'
import { Controller, useForm } from 'react-hook-form'
import { Meeting } from '@/types'
import { ChatContext } from '.'
import DurationSelect from './DurationSelect'

const CreateMeetingPopover = ({ children }: { children: ReactNode }) => {
  const { onCreateChat } = useContext(ChatContext)
  const { register, handleSubmit, reset, control, formState: { isSubmitting } } = useForm<Meeting>()
  // const durationProps = register('durationInMinutes')
  // pass durationProps to DurationSelect, dependent on what props you give DurationSelect

  const [open, setOpen] = useState(false)
  return (
    <Popover.Root open={open} onOpenChange={setOpen}>
      <Popover.Trigger>{children}</Popover.Trigger>
      <Popover.Content>
        <form
          onSubmit={handleSubmit((values) => {
            // TODO: call onCreateChat
            onCreateChat?.({
              ...values
            })
            setOpen(false)
            reset()
          })}
          className='flex gap-3 flex-col'
        >
          <fieldset>
            Name:
            <TextField.Root placeholder="Name" {...register('name')} />
          </fieldset>
          <Controller
            name="durationInMinutes"
            control={control}
            render={({ field: { onChange, value, ...durationProps } }) => (
              <DurationSelect
                durationInMinutes={value}
                onChange={onChange}
                durationProps={durationProps} // pass durationProps to DurationSelect
              />
            )}
          />
          {/* <DurationSelect
            durationInMinutes={0}
            onChange={function (): void {
              throw new Error('Function not implemented.')
            }}
            durationProps={durationProps} // pass durationProps to DurationSelect
          /> */}
          <Button disabled={isSubmitting} type="submit">Create</Button>
        </form>
      </Popover.Content>
    </Popover.Root>
  )
}

export default CreateMeetingPopover
