'use client'
import React, { useContext } from 'react'
import { usePathname, useRouter } from 'next/navigation'
import Select from 'react-select'
import { ChatContext } from '.'

const MeetingSelect = () => {
  const { chatList, currentChatRef } = useContext(ChatContext)

  const meetingId = currentChatRef?.current?.id

  const router = useRouter()
  const pathname = usePathname()

  const meetingIdPath = pathname.split('/chat').at(1)?.slice(1)

  const options = [{
    value: '',
    label: 'Spire (Assistant)',
    onClick: () => {
      router.push(`/chat`)
    }
  }, ...chatList.map((chat) => ({
    value: chat.id,
    label: chat.name,
    onClick: () => {
      router.push(`/chat/${chat.id}`)
    }
  }))]

  const styles = {
    container: (base: any) => ({
      ...base,
      flex: 1
    })
  };

  const handleChange = (selectedOption: any) => {
    if (selectedOption) {
      const chatId = selectedOption.value
      router.push(`/chat/${chatId}`)
    }
  }

  return <Select value={meetingIdPath ? (options.find(x => x.value === meetingIdPath) || '') : ''} styles={styles} options={options} onChange={handleChange} />
}

export default MeetingSelect
