'use client'
import { useState } from 'react'
import { castPromiseToUndefined } from '@planda/design-system'
import { useRouter } from 'next/navigation'
import { signIn } from 'next-auth/react'
import useSWR from 'swr'
import { GOOGLE_CALENDAR_SCOPES } from '@/constants/external'
import { fetchGet, fetcher } from '@/lib/fetch'
import { GoogleCalendarId, GoogleCalendarSettings } from '@/services/external/types'

export const useGoogleCalendar = (id?: string) => {
    const router = useRouter()
    const suffix = id ? `?id=${id}` : ''
    const [calendarIds, setCalendarIds] = useState<GoogleCalendarId[]>([])
    const { data, mutate } = useSWR<{ isEnabled: boolean, email: string }>(`/api/external/calendar/google${suffix}`, fetchGet)
    const { data: googleCalendarSettings, mutate: mutateCalendarSettings } = useSWR<GoogleCalendarSettings>(`/api/external/calendar/google/settings${suffix}`, fetchGet, {
        onSuccess: (data) => {
            setCalendarIds(data.calendars)
        }
    })

    const googleCalendarIsEnabled = !!data?.isEnabled

    const authorizeGoogle = async () => {
        mutate({ isEnabled: true, email: data?.email || '' }, false)
        if (!id) {
            await signIn('google', {}, { scope: GOOGLE_CALENDAR_SCOPES.join(' '), prompt: 'consent', access_type: 'offline' })
        } else {
            const url = await fetcher<{ url: string } | undefined>(`/api/external/calendar/google/access`, 'GET')
            url?.url && router.push(url.url)
        }
    }

    const disableGoogle = async () => {
        mutate({ isEnabled: false, email: data?.email || '' }, false)
        await fetch(`/api/external/calendar/google${suffix}`, { method: 'DELETE' })
    }

    const saveCalendarIds = async () => {
        const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
        const enabledCalendars = calendarIds.filter(x => x.enabled).map(x => x.id)
        await fetcher(`/api/external/calendar/google/settings${suffix}`, 'PATCH', { set: { enabledCalendars, defaultTimeZone: timeZone } });
        mutateCalendarSettings()
    }

    async function editSettings(updates: Record<string, any>) {
        const remove = Object.keys(updates).filter((key) => updates[key] === undefined)
        const usedPromise = fetcher(`/api/external/calendar/google/settings${suffix}`, 'PATCH', { set: updates, remove });
        // const asyncWrapFetchGet = async () => {
        //     await usedPromise
        //     return fetchGet<CategoryRes>(`/api/main/category`)
        // }
        mutateCalendarSettings(castPromiseToUndefined(usedPromise), {
            optimisticData: (x) => (x ? { ...x, ...updates } : { calendars: [] }),
            populateCache: false,
        })
    }

    return {
        googleCalendarIsEnabled,
        authorizeGoogle,
        disableGoogle,
        calendarIds,
        saveCalendarIds,
        setCalendarIds,
        googleCalendarSettings,
        editSettings,
        email: data?.email,
    }
}
