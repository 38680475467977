import React, { forwardRef } from 'react'
import { range } from 'lodash'
import Select, { Theme } from 'react-select'

interface DurationSelectProps {
  durationInMinutes: number
  onChange: (d: number) => void
  durationProps: any // Modify the type according to the type of durationProps
}

export const selectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    neutral80: 'var(--colors-text)',
    neutral20: 'var(--colors-outline)',
    primary25: 'var(--colors-primary3)',
    neutral10: 'var(--colors-secondary7)',
    primary: 'var(--colors-gray11)'
  }
})

export const minutesToLabel = (value: number) => {
  const hours = Math.floor(value / 60)
  const min = value % 60
  let label = ''
  if (hours) label += `${hours}h`
  if (hours && min) label += ' '
  if (min) label += ` ${min}m`
  return label
}

const onBlurWorkaround = (event: React.FocusEvent<HTMLInputElement>) => {
  const element = event.relatedTarget
  if (
    element &&
    (element.tagName === 'A' || element.tagName === 'BUTTON' || element.tagName === 'INPUT')
  ) {
    ;(element as HTMLElement).focus()
  }
}

const DurationSelect = forwardRef<any, DurationSelectProps>(
  ({ durationInMinutes, onChange, durationProps, ...props }, ref) => {
    const options = range(15, 600, 15).map((minutes) => {
      return { value: minutes, label: minutesToLabel(minutes) }
    })

    const customStyles = {
      option: (provided: any, state: any) => ({
        ...provided,
        color: 'black'
      }),
      singleValue: (provided: any) => ({
        ...provided,
        color: 'black'
      })
    }

    return (
      <div style={{ width: 150 }}>
        <Select
          placeholder="Duration"
          options={options}
          value={options.find((option) => option.value === durationInMinutes)}
          name={'duration-select'}
          ref={ref}
          theme={selectTheme}
          menuPortalTarget={document.body}
          // styles={{
          //   menuPortal: base => ({ ...base, zIndex: 9999 })
          // }}
          styles={customStyles}
          onChange={(newValue: { value: number; label: string } | null, actionMeta) => {
            if (actionMeta.action === 'select-option') {
              onChange(newValue?.value || 60)
            } else if (actionMeta.action === 'clear') {
              onChange(60)
            }
          }}
          onBlur={onBlurWorkaround}
          {...durationProps} // Pass durationProps to Select component
        />
      </div>
    )
  }
)

DurationSelect.displayName = 'DurationSelect'
export default DurationSelect
