import React from 'react'
// import { Button, Checkbox, Flex, Heading, Select, Switch, Text, ContentUnit } from '@planda/design-system'
import { Button, Checkbox, Flex, Heading, Switch, Text } from '@radix-ui/themes'
import { useGoogleCalendar } from './useGoogleCalendar'
import { GoogleButton } from '../SignInButtons'

// const scope = 'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar https://www.google.com/calendar/feeds/ https://www.google.com/calendar/feeds http://www.google.com/calendar/feeds/default/allcalendars/full https://www.google.com/calendar/feeds/default/owncalendars/full http://www.google.com/calendar/feeds/ http://www.google.com/calendar/feeds https://www.google.com/calendar/feeds/default/private/full http://www.google.com/calendar/feeds/default/private/full http://www.google.com/calendar/feeds/default/owncalendars/full/ https://www.google.com/calendar/feeds/default https://www.google.com/calendar/freebusy https://www.googleapis.com/auth/calendar.app.created https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.freebusy https://www.googleapis.com/auth/calendar.events.owned https://www.googleapis.com/auth/calendar.events.owned.readonly https://www.googleapis.com/auth/calendar.events.public.readonly https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly'


// TODO check if signin acually changes access_token
const GoogleCalendar = ({ id }: { id?: string }) => {
  const { googleCalendarIsEnabled: firstCalEnabled } = useGoogleCalendar()
  const { googleCalendarIsEnabled, authorizeGoogle, disableGoogle, saveCalendarIds, calendarIds, setCalendarIds, email } = useGoogleCalendar(id)
  // `external/google/${calendarId}`,

  if (id && !firstCalEnabled) return <Flex className='h-full' direction={'column'} align={'center'} gap={"5"} >
    <Heading>Connect to first Google Calendar before adding a secondary calendar.</Heading></Flex>

  return (
    <Flex className='h-full' direction={'column'} align={'center'} gap={"5"} >
      <Heading>Connect {id ? "Secondary" : ''} Google Calendar <Switch onCheckedChange={(checked) => {
        if (checked) {
          authorizeGoogle()
        } else {
          disableGoogle()
        }
      }} checked={googleCalendarIsEnabled} /></Heading>
      {googleCalendarIsEnabled && email && <Text>Connected to {email}</Text>}
      <Text>To opt in: Contact us if you want to sync with Google Calendar.</Text>
      <GoogleButton
        onClick={authorizeGoogle}
      />
      <Button onClick={disableGoogle} >Disable Google Calendar sync.</Button>

      {googleCalendarIsEnabled && <>
        <div>
          <h3>Calendars to sync with:</h3>
          <h6>Select sections to automatically add to calendar</h6>
          {calendarIds.map(({ id, name, enabled }, i) => {
            return (
              <div key={id}>
                <Flex gap="2">
                  <Checkbox
                    key={id}
                    checked={enabled}
                    onCheckedChange={(val) => val !== 'indeterminate' && setCalendarIds(p => {
                      p[i].enabled = val
                      return [...p]
                    })}
                  />
                  {name}
                </Flex>
              </div>
            );
          })}
          <Button onClick={saveCalendarIds} >Save</Button>
        </div>
      </>}
      {/* {process.env.NODE_ENV === 'development' &&
        <Button onClick={() => { window.confirm("Cannot be relinked after unlinked") && editSettings({ aliasToCalendarId: {}, plandaCalendarId: undefined, defaultWriteCalendar: undefined, enabledCalendars: undefined }) }} >Unlink all category calendars (cannot be undone)</Button>} */}

      <label style={{ maxWidth: 400, alignSelf: 'flex-end' }}>
        Ethospire&apos;s use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy, including the Limited Use requirements.
      </label>
    </Flex>
  )
}

export default GoogleCalendar